"use strict";

jQuery(document).ready(function ($) {
  $('.titleBlock').on('click', function () {
    $('.titleBlock').not(this).removeClass('active');
    $(this).toggleClass('active');
    var ThisContent = $(this).parents('.benefits-accordion').find('.benefits-accordion-content');
    $('.benefits-accordion-content').not(ThisContent).slideUp();
    ThisContent.slideToggle();
  });
  $('.js-tab-title').on('click', function () {
    var ThisParentsTitle = $(this).parents('.js-pagination-holder'),
      ThisImage = ThisParentsTitle.parents('.benefits-row').find('.js-imageHolder').eq(ThisParentsTitle.index());
    if (!ThisParentsTitle.hasClass('active')) {
      $('.js-pagination-holder').not(ThisParentsTitle).removeClass('active');
      ThisParentsTitle.addClass('active');
      $('.js-text-container').slideUp();
      $(this).next().slideDown();
      $('.js-imageHolder').not(ThisImage).removeClass('active');
      ThisImage.addClass('active');
    }
  });
});